import React, { useCallback } from "react";

import { Box, Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";

import AssetTypeMaster from "./AssetTypeMaster";
import BrandMaster from "./BrandMaster";
import ClassificationMaster from "./ClassificationMaster";
import Admin from "./Admin";
import StatusMaster from "./StatusMaster";
import AdminCor from "./AdminCor";
// import AssignedItems from "./AssignedItems";

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <Box>{value === index && <>{children}</>}</Box>;
};

const AdminCorner = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 52px)",
        }}
      >
        <Box sx={{ margin: "1rem" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabIndex}
              onChange={(_, newIndex) => handleTabChange(newIndex)}
            >
              <Tab label="Asset Type Master" sx={{ fontWeight: "bold" }} />
              <Tab label="Brand Master" sx={{ fontWeight: "bold" }} />
              <Tab label="Classification Master" sx={{ fontWeight: "bold" }} />
              <Tab label="Status Master" sx={{ fontWeight: "bold" }} />
              <Tab label="Admin Corner" sx={{ fontWeight: "bold" }} />
              {/* <Tab label=' Assigned Items' sx={{ fontWeight: 'bold' }} /> */}
              {/* <Tab label='Project Type - Activity Mapping' sx={{ fontWeight: 'bold' }} /> */}
            </Tabs>
          </Box>
          <TabPanel value={tabIndex} index={0}>
            <AssetTypeMaster />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <BrandMaster />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <ClassificationMaster />
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <StatusMaster />
          </TabPanel>
          <TabPanel value={tabIndex} index={4}>
            <AdminCor />
          </TabPanel>
          {/* <TabPanel value={tabIndex} index={4}>
            <AssignedItems/>
          </TabPanel> */}
        </Box>
      </div>
    </>
  );
};

export default AdminCorner;
