import React, { useEffect, useMemo, useState } from "react";
import LaptopIcon from "@mui/icons-material/Laptop";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CountUp, { useCountUp } from "react-countup";
import { fontWeight } from "@mui/system";
import {
  AssignmentLate,
  AssignmentTurnedIn,
  Inventory,
  PhoneIphone,
} from "@mui/icons-material";
import "./HomeInfoCard.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";
import { MaterialReactTable } from "material-react-table";

const HomeInfoCards = () => {
  // console.log("statusCount", statusCount);
  const [statusCount, setStatusCount] = useState([]);
  const [assetType, setAssetTypeName] = useState([]);
  const [showTable, setShowTable] = useState({
    open: false,
    val: "",
  });
  const [data, setData] = useState([]);
  const [EditFormData, setEditFormData] = useState({
    assetType: "",
    assetTypeId: "1",
  });
  const inputSize = {
    style: {
      height: "5vh",
      maxHeight: "32px",
      width: "15em",
      padding: "2px 10px",
    },
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "asset_id",
        header: "Asset ID",
        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "asset_sl_no",
        header: "Asset Serial No",
        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      EditFormData.assetTypeId == "1" && {
        accessorKey: "storage",
        header: "Storage",
        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },

      EditFormData.assetTypeId == "1" && {
        accessorKey: "ram", //normal accessorKey
        header: "RAM",
        size: 100,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },

      {
        accessorKey: "model",
        header: "Model",
        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "comments",
        header: "Remarks",
        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "asset_st_date",
        header: "Assign Date",
        size: 100,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          if (cell.row.original.asset_st_date === null) {
            return "-";
          } else {
            const date = new Date(parseInt(cell.row.original.asset_st_date));
            const formattedDate = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
            return formattedDate;
          }
        },
      },
      {
        accessorKey: "asset_end_date",
        header: "Return Date",
        size: 100,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          if (cell.row.original.asset_end_date === null) {
            return "-";
          } else {
            const date = new Date(parseInt(cell.row.original.asset_end_date));
            const formattedDate = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
            return formattedDate;
          }
        },
      },
    ],
    [EditFormData.assetTypeId]
  );
  const textColor = (type) => {
    if (type === 1) {
      return "#4CAF50";
    } else if (type === 5) {
      return "#F44336";
    } else {
      return "#3026B9";
    }
  };

  const getStatusDetails = async (statusId, assetTypeId) => {
    try {
      let res = await axios.get(
        `${baseUrl}/by-statusId?statusId=${statusId}&assetTypeId=${assetTypeId}`
      );
      if (res.data.status === "success") {
        // console.log("status data:", res.data.data);
        setData(res.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusCount = async (id) => {
    // debugger;
    try {
      let res = await axios.get(
        `${baseUrl}/status-count/asset-type?assetTypeId=${id}`
      );
      // let res = await axios.get("http://localhost:8080/getAssetTypeName");
      // console.log(res);
      if (res.data.status === "success") {
        // console.log("status count:", res.data.data);
        setStatusCount(res.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getAssetTypeName = async () => {
    // debugger;
    try {
      let res = await axios.get(`${baseUrl}/allAssetTypes`);
      // let res = await axios.get("http://localhost:8080/getAssetTypeName");
      console.log(res);
      if (res.data.result === "success") {
        console.log("assetTypeName", res.data.data);
        setAssetTypeName(res.data.data);
        if (res.data.data.length > 0) {
          const defaultAsset = res.data.data[0];
          setEditFormData({
            assetType: defaultAsset.assetTypeName,
            assetTypeId: defaultAsset.assetTypeId,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  let handleOnChange = (e) => {
    // debugger
    //single function handling both updating the data and adding the data, form data is handled in a single EditFromData state reason for data structure being same
    let value = e.target.value;
    let name = e.target.name;

    // const selectedClassification=classification.find((option) => option.classificationName === value);
    // const classificationId = selectedClassification ? selectedClassification.assetClassificationId : null;

    let stateData = { ...EditFormData };

    if (name === "assetTypeId") {
      const selectedAsset = assetType.find(
        (option) => option.assetTypeName === value
      );
      const assetId = selectedAsset ? selectedAsset.assetTypeId : null;
      stateData.assetType = value;
      stateData[name] = assetId;
    }

    setEditFormData(stateData);
  };

  useEffect(() => {
    getAssetTypeName();
    // getStatusCount();
  }, []);
  useEffect(() => {
    setShowTable({ open: false });
    console.log("EditFormData", EditFormData);
    getStatusCount(EditFormData.assetTypeId);
  }, [EditFormData]);

  return (
    // <Paper sx={{margin: '1em', height: '84px', backgroundColor: '#F1F5FE'}} elevation={4}>

    <Paper
      className="home-info-cards"
      elevation={3}
      sx={{ borderRadius: "7px", padding: "1em" }}
    >
      <Stack spacing={2} direction={"column"} width={"100%"}>
        <Stack
          spacing={2}
          direction={"column"}
          justifyContent={"space-between"}
        >
          <Grid container rowSpacing={3}>
            <Grid item xs={6}>
              <Typography sx={{ color: "#424242", width: "100%" }}>
                Asset Type
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  select
                  required
                  // disabled={false}

                  // sx={{ width: "100%", }}
                  margin="normal"
                  SelectProps={inputSize}
                  // InputProps={inputSize}

                  id="outlined-basic"
                  variant="outlined"
                  name="assetTypeId"
                  // value={EditFormData.assetType}
                  value={
                    assetType.find(
                      (option) =>
                        option.assetTypeId === EditFormData.assetTypeId
                    )?.assetTypeName || ""
                  }
                  onChange={handleOnChange}
                >
                  {assetType
                    .filter((item) => item.isActive === true)
                    .map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.assetTypeName}>
                          {option.assetTypeName}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
            {statusCount.map((e, index) => (
              // <Tooltip title={<Element items={e.details} />} arrow>
              <Paper
                onClick={() => {
                  // console.log("status id :", e.status_id);
                  getStatusDetails(e.status_id, EditFormData.assetTypeId);
                  setShowTable({
                    open: true,
                    val: e.status_id,
                  });
                }}
                className="home-info-individual-card"
                style={{
                  backgroundColor: "#F1F5FE",
                  padding: "8px",
                  cursor: "pointer",
                }}
                elevation={3}
                sx={{
                  width: "150px",
                  height: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* {e.icon} */}
                  <Typography
                    variant="h4"
                    fontSize={"90%"}
                    textAlign={"center"}
                  >
                    {e.status_id === 1
                      ? "Assigned"
                      : e.status_id === 2
                      ? "Disposed"
                      : e.status_id === 3
                      ? "Donated"
                      : e.status_id === 4
                      ? "Lost And Recovered"
                      : e.status_id === 5
                      ? "Spare"
                      : e.status_id === 6
                      ? "Damaged"
                      : "NOT RETURNED"}
                  </Typography>
                </div>
                <CountUp start={0} end={e.count} delay={0}>
                  {({ countUpRef }) => (
                    // <Typography variant='h4' fontSize={'177%'} color='#007AD4'>{e.qty}</Typography>
                    <>
                      <span
                        style={{
                          fontSize: "180%",
                          color: textColor(e.status_id),
                          fontWeight: "600",
                        }}
                        ref={countUpRef}
                      />
                    </>
                  )}
                </CountUp>
              </Paper>
              // {/* </Tooltip> */}
            ))}
          </Stack>
        </Stack>
        <Stack spacing={2} direction={"row"} justifyContent={"flex-start"}>
          {showTable.open && (
            <Accordion sx={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {showTable.val === 1
                  ? "Assigned Detail"
                  : showTable.val === 2
                  ? "Disposed Detail"
                  : showTable.val === 3
                  ? "Donated Detail"
                  : showTable.val === 4
                  ? "Lost And Recovered Detail"
                  : showTable.val === 5
                  ? "Spare Detail"
                  : showTable.val === 6
                  ? "Damaged Detail"
                  : "NOT RETURNED Detail"}
              </AccordionSummary>
              <AccordionDetails>
                <CustomTable columns={columns} data={data} />
              </AccordionDetails>
            </Accordion>
          )}
        </Stack>
      </Stack>
    </Paper>
    // </Paper>
    // <Paper sx={{margin: '1em', height: '84px', backgroundColor: '#F1F5FE'}} elevation={4}>

    //     <Box sx={{height: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
    //     {
    //     data.map((e, index) => (
    // <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
    //         {e.icon}
    //         <Typography variant='h4' fontSize={18} style={{marginRight: '1.2em'}}>{e.type}</Typography>
    //         <Typography variant='h4' fontSize={18} color='#007AD4'>{e.qty}</Typography>
    //         {
    //             index!==data.length-1 ? <div className='home-info-separator'></div> : null
    //         }
    //         </div>
    //     ))
    //     }
    //     </Box>
    // </Paper>
  );
};

export default HomeInfoCards;

const CustomTable = ({ columns, data }) => {
  return (
    <Box>
      <MaterialReactTable columns={columns.filter(Boolean)} data={data} />
    </Box>
  );
};

const Element = ({ items = {} }) => {
  return (
    <>
      {Object.keys(items).map((key) => (
        <p>
          {key} : {items[key]}
        </p>
      ))}
    </>
  );
};
