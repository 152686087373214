import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";
function StatusMaster() {
  const inputSize = {
    style: {
      height: "5vh",
      maxHeight: "32px",
      width: "15em",
      padding: "2px 10px",
    },
  };
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);
  const [statusMaster, setStatusMaster] = useState([]);
  // const [state, setState]= useState([])
  //   const statusMaster = ["spare", "disposed", "Assigned"];
  const [showAdd, setShowAdd] = useState({
    open: false,
    val: "",
  });
  const [editFormData, setEditFormData] = useState({
    statusName: "",
    isActive: true,
    // classification: "",
  });
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleMenuOpen = (event, row) => {
    console.log("row", row);
    // setRowData(rowData)
    setShowAdd({
      open: true,
      val: "",
    });
    setShowMenu(true);
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setShowMenu(false);
    setShowAdd({
      open: false,
      val: "",
    });
    //   setEditFormData({
    //     brandName: "",
    //   });
  };

  const handleSwitch = (statusMaster) => {
    // debugger;
    setStatusMaster((prev) =>
      prev.map((item) =>
        item.statusId === statusMaster.statusId
          ? { ...item, isActive: !item.isActive }
          : item
      )
    );
    setStatus(true);
    setSelectedRow(statusMaster);
    setEditFormData({
      // statusName: selectedRow?.statusName,
      isActive: !statusMaster?.isActive,
    });
  };

  const handleOnChange = (e) => {
    setEditFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  ///get all status///
  const getStatus = async () => {
    try {
      const response = await axios.get(`${baseUrl}/getAllStatuses`);
      console.log("status :", response);
      if (response.data.result === "success") {
        // const data = response.data.data.filter(
        //   (item) => item.isActive === true
        // );
        setStatusMaster(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /////////delete api///
  //   const handleDelete = async () => {
  //     // debugger;
  //     console.log("delete", selectedRow);
  //     try {
  //       const res = await axios.delete(
  //         `${baseUrl}/deleteStatus/${selectedRow.statusId}`
  //       );
  //       console.log("delete response", res);
  //       if (res.data.result === "success") {
  //         getStatus();
  //         handleClose();
  //         // setStatus(false);
  //         setSnackBarMessage(`${selectedRow.statusName} is deactivated `);
  //         setSnackBarSeverity("success");
  //         setSnackBarStatus(true);
  //         handleClose();
  //       } else if (res.data.result === "failed") {
  //         setSnackBarMessage(res.data.message);
  //         setSnackBarSeverity("error");
  //         setSnackBarStatus(true);
  //       } else {
  //         setSnackBarMessage("Some thing went wrong");
  //         setSnackBarSeverity("error");
  //         setSnackBarStatus(true);
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };

  //////////add status ////////////
  const createStatus = async () => {
    // alert("save");
    console.log("123", editFormData);
    if (editFormData.statusName === "") {
      setSnackBarMessage("Name field is empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else {
      try {
        const res = await axios.post(`${baseUrl}/addStatus`, editFormData);
        console.log("add response", res);
        if (res.data.result === "success") {
          getStatus();
          setEditFormData({
            statusName: "",
          });
          handleClose();
          setSnackBarMessage("Data Added Successfully");
          setSnackBarSeverity("success");
          setSnackBarStatus(true);
          handleClose();
        } else if (res.data.result === "failed") {
          setSnackBarMessage(res.data.message);
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        } else {
          setSnackBarMessage("Some thing went wrong");
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  ////////update api/////////
  const handleUpdate = async () => {
    // alert("update");

    console.log("123", editFormData);
    if (editFormData.statusName === "") {
      setSnackBarMessage("Name field is empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else {
      try {
        const res = await axios.put(
          `${baseUrl}/updateStatus/${selectedRow.statusId}`,
          editFormData
        );
        console.log("add response", res);
        if (res.data.result === "success") {
          getStatus();
          setEditFormData({
            statusName: "",
          });
          setStatus(false);
          handleClose();
          setSnackBarMessage("Data Updated Successfully");
          setSnackBarSeverity("success");
          setSnackBarStatus(true);
          handleClose();
        } else if (res.data.result === "failed") {
          setSnackBarMessage(res.data.message);
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        } else {
          setSnackBarMessage("Some thing went wrong");
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    getStatus();
  }, []);
  useEffect(() => {
    if (selectedRow && status === false) {
      setEditFormData({
        statusName: selectedRow?.statusName,
        // isActive: selectedRow?.isActive,
        // classificationName: selectedRow.classificationMaster?.classificationName,
      }); // Initialize form data with selected row when selectedRow changes
    }
  }, [selectedRow]);
  // useEffect(() => {
  //   if (statusMaster) {
  //     setEditFormData({
  //       // statusName: selectedRow?.statusName,
  //       isActive: statusMaster?.isActive,
  //       // classificationName: selectedRow.classificationMaster?.classificationName,
  //     }); // Initialize form data with selected row when selectedRow changes
  //   }
  // }, [statusMaster]);
  //   useEffect(() => {
  //     if (status) {
  //       handleDelete();
  //     }
  //   }, [statusMaster]);
  useEffect(() => {
    if (status) {
      handleUpdate();
    }
  }, [editFormData]);

  return (
    <>
      <Box sx={{ margin: "auto", width: "60%", marginTop: "20px" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={60} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={30} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={30} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={30} />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F1F5FE" }}>
                  <TableCell sx={{ fontWeight: "bold" }}>Sl. No.</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}> Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Active</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {statusMaster?.map((statusMaster, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{statusMaster.statusName}</TableCell>
                    {/* {statusMaster.isActive ? (
                      <TableCell>
                        <Switch checked={true} name="isActive"/>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Switch checked={false} name="isActive"/>
                      </TableCell>
                    )} */}
                    <TableCell>
                      <Switch
                        checked={statusMaster.isActive}
                        name="isActive"
                        onChange={() => handleSwitch(statusMaster)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        // disabled
                        disabled={!statusMaster.isActive}
                        onClick={(e) => handleMenuOpen(e, statusMaster)}
                      >
                        {/* <Button> */}
                        <EditIcon />
                      </Button>
                      {/* <Menu
                        anchorEl={anchorEl}
                        open={showMenu}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() =>
                            setShowAdd({
                              open: true,
                              val: "update",
                            })
                          }
                        >
                          Update
                        </MenuItem>

                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                      </Menu> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                startIcon={<AddCircleIcon />}
                sx={{
                  margin: "1rem",
                  border: "1px solid #F1F5FE",
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  height: "max-content",
                  "&:hover": {
                    backgroundColor: "#F1F5FE",
                    color: "#0288d1",
                    border: "1px solid #0288d1",
                    boxShadow: "1px #000000",
                  },
                }}
                onClick={() =>
                  setShowAdd({
                    open: true,
                    val: "Add",
                  })
                }
              >
                Add
              </Button>
            </Box>
          </TableContainer>
        )}
      </Box>
      {/* *modal ADD NEW DATA***  */}
      <Dialog open={showAdd.open} sx={{ padding: "20px" }}>
        <DialogContent sx={{ padding: "10px" }}>
          <Stack>
            <Grid container spacing={8} rowSpacing={3}>
              <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>
                  Name<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  type={"text"}
                  InputProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="statusName"
                  inputProps={{ min: 0 }}
                  value={editFormData.statusName}
                  onChange={handleOnChange}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              if (showAdd.val === "Add") {
                createStatus();
              } else {
                handleUpdate();
              }
            }}
            variant="contained"
            sx={{
              backgroundColor: "#0288d1",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#140B96",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              backgroundColor: "#E0E0E0",
              textTransform: "none",
              color: "black",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        autoHideDuration={4000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default StatusMaster;
