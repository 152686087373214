import React, { useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useState } from "react";
import { setSelectedNavIndex } from "../../redux/reducers/appReducer";
import { useDispatch } from "react-redux";
// import xlsx from 'json-as-xlsx'
// import { FilterAltOff } from '@mui/icons-material';
// import RegisteredAssets from '../../Components/InventoryPanels/RegisteredAssets';

import "./Inventory.css";
import OtherAssets from "../../Components/InventoryPanels/OtherAssets";
// import AgGridRegisteredAsset from '../../Components/InventoryPanels/AgGridRegisteredAsset';
import PaginatedMuiGridRegisteredAssets from "../../Components/InventoryPanels/PaginatedMuiGridRegisteredAssets";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";
import GetAssets from "../../Components/InventoryPanels/GetAssets";

const Inventory = () => {
  const dispatch = useDispatch();
  dispatch(setSelectedNavIndex(1));
  // **master data********************
  //InventoryData is the source for the data sent to the table. data is stored by fetching the data from the database

  //tab state
  const [value, setValue] = useState(0);
  // const [assetTypeName, setAssetTypeName] = useState([]);
  const [classification, setClassification] = useState([]);

  const handleChange = (event, newValue) => {
    // debugger;
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return <>{value === index && <>{children}</>}</>;
  }

  //new change code to add assetTypeName

  const getClassificationName = async () => {
    // debugger;
    try {
      let res = await axios.get(`${baseUrl}/allClassifications`);
      // let res = await axios.get("http://localhost:8080/getAssetTypeName");
      console.log(res.data.data);
      if (res.data.result === "success") {
        setClassification(res.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  };
  // const getAssetTypeName = async () => {
  //   // debugger;
  //   try {
  //     let res = await axios.get(`${baseUrl}/getAssetTypeName`);
  //     // let res = await axios.get("http://localhost:8080/getAssetTypeName");
  //     console.log(res);
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       setAssetTypeName(res.data);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  useEffect(() => {
    getClassificationName();
    // getAsset();
  }, []);

  useEffect(() => {
    console.log("new data ", classification);
  }, [classification]);

  return (
    <>
      {/* <Paper style={{ height: 'calc(100vh - 52px - 3em)', margin: '1em', overflow: 'hidden', paddingBottom: '1rem', padding: '5px' }}> */}
      <Box sx={{ padding: "1em", top: "48px", position: "relative" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            // padding: "20px 0px 0px 20px",
            "& button": {
              fontWeight: "200",
              fontSize: "16px",
              borderRadius: "4px 4px 0 0",
              border: "solid 1px #ddd",
              padding: "8px 14px",
              textTransform: "none",
            },
            "& button.Mui-selected": {
              fontWeight: "bold",
              fontSize: "16px",
              backgroundColor: "#f1f5fe",
              color: "#0288d1",
              boxShadow: "0",
              padding: "0",
              padding: "8px 14px",
              boxShadow: "rgb(0 0 0 / 25%) 0px 4px 4px 2px",
            },
          }}
          style={{ width: "100%", minHeight: "30px" }}
          TabIndicatorProps={{ hidden: true }}
        >
          <Tab label="Registered Assets" />

          {classification
            .filter((item) => item.isActive === true)
            .map((name, index) => {
              return <Tab key={index} label={`${name.classificationName}`} />;
            })}

          {/* <Tab label="Other Assets" /> */}
        </Tabs>

        <TabPanel value={value} index={0}>
          {/* <AgGridRegisteredAsset />
          <>
            <Divider></Divider>
            <Box sx={{ height: "3em" }}></Box>
            <RegisteredAssets />
          </> */}
          {/* <> */}
          {/* <Divider></Divider> */}
          {/* <Box sx={{ height: "3em" }}></Box> */}
          <PaginatedMuiGridRegisteredAssets />
          {/* </> */}
        </TabPanel>

        {classification
          .filter((item) => item.isActive === true)
          .map((name, index) => {
            return (
              <TabPanel key={index} value={value} index={index + 1}>
                {/* <OtherAssets /> */}
                {/* {name} */}
                <GetAssets classificationName={name.classificationName} />
              </TabPanel>
            );
          })}
        {/* 
        <TabPanel value={value} index={1}>
          <OtherAssets />
        </TabPanel> */}
      </Box>
      {/* </Paper > */}
    </>
  );
};

export default Inventory;
