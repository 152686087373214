import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";
function BrandMaster() {
  // const brandMaster=[{name:"HP",classification:"it asset"},{name:"Acer",classification:"it asset"},{name:"Dell",classification:"it asset"}]

  // const classification = ["it asset", "admin"];
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const [brandMaster, setBrandMaster] = useState([]);
  const [loading, setLoading] = useState(true);
  const inputSize = {
    style: {
      height: "5vh",
      maxHeight: "32px",
      width: "15em",
      padding: "2px 10px",
    },
  };

  const [showAdd, setShowAdd] = useState({
    open: false,
    val: "",
  });
  const [editFormData, setEditFormData] = useState({
    brandName: "",
    isActive: true,

    // classification: "",
  });

  const [status, setStatus] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleMenuOpen = (event, row) => {
    console.log("row", row);
    // setRowData(rowData)
    setShowAdd({
      open: true,
      val: "",
    });
    setShowMenu(true);
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const handleSwitch = (brandMaster) => {
    setBrandMaster((prev) =>
      prev.map((item) =>
        item.brandId === brandMaster.brandId
          ? { ...item, isActive: !item.isActive }
          : item
      )
    );
    setStatus(true);
    setSelectedRow(brandMaster);
    setEditFormData({
      // statusName: selectedRow?.statusName,
      isActive: !brandMaster?.isActive,
    });
  };
  const handleClose = () => {
    setShowMenu(false);
    setShowAdd({
      open: false,
      val: "",
    });
    setEditFormData({
      brandName: "",
    });
  };

  const handleOnChange = (e) => {
    setEditFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  ///////////add api
  const createBrand = async () => {
    // alert("save");
    console.log("123", editFormData);
    if (editFormData.brandName === "") {
      setSnackBarMessage("Name field is empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else {
      try {
        const res = await axios.post(`${baseUrl}/createBrand`, editFormData);
        console.log("add response", res);
        if (res.data.result === "success") {
          handleData();
          setEditFormData({
            brandName: "",
          });
          handleClose();
          setSnackBarMessage("Data Added Successfully");
          setSnackBarSeverity("success");
          setSnackBarStatus(true);
          handleClose();
        } else if (res.data.result === "failed") {
          setSnackBarMessage(res.data.message);
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        } else {
          setSnackBarMessage("Some thing went wrong");
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };
  ////////update api
  const handleUpdate = async () => {
    // alert("update");
    console.log("123", editFormData);
    if (editFormData.brandName === "") {
      setSnackBarMessage("Name field is empty");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    } else {
      try {
        const res = await axios.put(
          `${baseUrl}/updateBrand/${selectedRow.brandId}`,
          editFormData
        );
        console.log("add response", res);
        if (res.data.result === "success") {
          handleData();
          setEditFormData({
            brandName: "",
          });
          handleClose();
          setStatus(false);
          setSnackBarMessage("Data Updated Successfully");
          setSnackBarSeverity("success");
          setSnackBarStatus(true);
          handleClose();
        } else if (res.data.result === "failed") {
          setSnackBarMessage(res.data.message);
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        } else {
          setSnackBarMessage("Some thing went wrong");
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };
  ///////////delete api

  // const handleDelete = async () => {
  //   console.log("delete", selectedRow);
  //   try {
  //     const res = await axios.delete(
  //       `${baseUrl}/deleteBrand/${selectedRow.brandId}`
  //     );
  //     console.log("delete response", res);
  //     if (res.data.result === "success") {
  //       handleData();
  //       handleClose();
  //       setStatus(false);
  //       setSnackBarMessage(`${selectedRow.brandName} is deactivated`);
  //       setSnackBarSeverity("success");
  //       setSnackBarStatus(true);
  //       handleClose();
  //     } else if (res.data.result === "failed") {
  //       setSnackBarMessage(res.data.message);
  //       setSnackBarSeverity("error");
  //       setSnackBarStatus(true);
  //     } else {
  //       setSnackBarMessage("Some thing went wrong");
  //       setSnackBarSeverity("error");
  //       setSnackBarStatus(true);
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  ///////////get brandmaster api
  const handleData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/allBrands`);
      console.log("brandMaster", response.data);
      if (response.data.result === "success") {
        setBrandMaster(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      //  setLoading(false)
    }
  };
  useEffect(() => {
    handleData();
  }, []);
  useEffect(() => {
    if (selectedRow && status === false) {
      setEditFormData({
        brandName: selectedRow?.brandName,
        // classificationName: selectedRow.classificationMaster?.classificationName,
      }); // Initialize form data with selected row when selectedRow changes
    }
  }, [selectedRow]);
  useEffect(() => {
    if (status) {
      handleUpdate();
    }
  }, [editFormData]);

  return (
    <>
      <Box sx={{ margin: "auto", width: "60%", marginTop: "20px" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={60} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={30} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={30} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={30} />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F1F5FE" }}>
                  <TableCell sx={{ fontWeight: "bold" }}>Sl. No.</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}> Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Active</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {brandMaster?.map((brandMaster, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{brandMaster.brandName}</TableCell>
                    <TableCell>
                      <Switch
                        checked={brandMaster.isActive}
                        name="isActive"
                        onChange={() => handleSwitch(brandMaster)}
                      />
                    </TableCell>
                    {/* <TableCell>{brandMaster.classification}</TableCell> */}
                    <TableCell>
                      <Button
                        disabled={!brandMaster.isActive}
                        onClick={(e) => handleMenuOpen(e, brandMaster)}
                      >
                        <EditIcon />
                      </Button>
                      {/* <Menu
                        anchorEl={anchorEl}
                        open={showMenu}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() =>
                            setShowAdd({
                              open: true,
                              val: "update",
                            })
                          }
                        >
                          Update
                        </MenuItem>

                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                      </Menu> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                startIcon={<AddCircleIcon />}
                sx={{
                  margin: "1rem",
                  border: "1px solid #F1F5FE",
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  height: "max-content",
                  "&:hover": {
                    backgroundColor: "#F1F5FE",
                    color: "#0288d1",
                    border: "1px solid #0288d1",
                    boxShadow: "1px #000000",
                  },
                }}
                onClick={() =>
                  setShowAdd({
                    open: true,
                    val: "Add",
                  })
                }
              >
                Add
              </Button>
            </Box>
          </TableContainer>
        )}
      </Box>
      {/* *modal ADD NEW DATA***  */}
      <Dialog open={showAdd.open} sx={{ padding: "20px" }}>
        <DialogContent sx={{ padding: "10px" }}>
          <Stack>
            <Grid container spacing={8} rowSpacing={3}>
              <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>
                  Name<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  type={"text"}
                  InputProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="brandName"
                  inputProps={{ min: 0 }}
                  value={editFormData.brandName}
                  onChange={handleOnChange}
                  autoComplete="off"
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>
                  {" "}
                  Classification
                </Typography>

                <TextField
                  select
                  SelectProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="classification"
                  defaultValue={editFormData.classification}
                  onChange={handleOnChange}
                >
                  {classification.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>;
                  })}
                </TextField>
              </Grid> */}
            </Grid>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              if (showAdd.val === "Add") {
                createBrand();
              } else {
                handleUpdate();
              }
            }}
            variant="contained"
            sx={{
              backgroundColor: "#0288d1",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#140B96",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              backgroundColor: "#E0E0E0",
              textTransform: "none",
              color: "black",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        autoHideDuration={4000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default BrandMaster;
