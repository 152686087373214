import { useEffect, useMemo, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";
import { useSelector } from "react-redux";

// const data = [
//   {
//     id: 1,
//     assetType: "Laptop",
//     uploadedBy: "-",
//   },
//   // {
//   //   id: 2,
//   //   assetType: "Laptop",
//   //   uploadedBy: "virat",
//   // },
//   // {
//   //   id: 3,
//   //   assetType: "Laptop",
//   //   uploadedBy: "pant",
//   // },
//   // {
//   //   id: 4,
//   //   assetType: "Laptop",
//   //   uploadedBy: "starc",
//   // },
//   // {
//   //   id: 5,
//   //   assetType: "Laptop",
//   //   uploadedBy: " pat cummins",
//   // },
// ];

const AdminCor = () => {
  const user = useSelector((state) => state.userReducer.userData);
  // console.log("user :", user);
  const inputSize = {
    style: {
      height: "5vh",
      maxHeight: "32px",
      width: "19em",
      padding: "2px 10px",
    },
  };
  /////add loading state//////////
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [data, setData] = useState([
  //   { id: 1, uploadingAssetType: "Laptop", lastUpdatedAt: "-" },
  // ]);
  const [currentUser, setCurrentUser] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [showupload, setShowUpload] = useState({
    show: false,
    cell: {},
  });
  const [uploadData, setUploadData] = useState([]);
  const showUpload = (cell) => {
    // console.log("upload");
    setShowUpload({ show: true, cell: cell.row.original });
  };

  const handleClose = () => {
    setShowUpload({ show: false, cell: {} });
    setLoading(false);
  };

  const handleOnChange = (e) => {
    // debugger;
    // let name = e.target.name;
    // if (name === "uploadingAssetType") {
    //   setData({ ...data, uploadingAssetType: e.target.value });
    // } else {
    //   setUploadData(e.target.files[0]);
    // }
    setUploadData(e.target.files[0]);
  };

  const handleUpload = async (data) => {
    // console.log("data :", data.uploadingAssetType);
    setShowUpload({ show: false, cell: {} });
    setLoading(true);
    const formData = new FormData();
    formData.append("file", uploadData);
    formData.append("uploadedBy", user.name);
    formData.append("uploadingAssetType", data.uploadingAssetType);
    // console.log("file :" + formData);
    // debugger;
    try {
      let res = await axios.post(`${baseUrl}/process-excel`, formData);
      console.log(" new response  :", res);
      if (res.status === 200) {
        setCurrentUser(true);

        setShowUpload({ show: false, cell: {} });
        setLoading(false);
        setSnackBarStatus(true);
        setSnackBarSeverity("success");
        setSnackBarMessage("File successfully uploaded");
        handleData();
      } else {
        setShowUpload({ show: false, cell: {} });
        setLoading(false);

        setSnackBarStatus(true);
        setSnackBarSeverity("error");
        setSnackBarMessage("Failed to upload file");
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
      setSnackBarStatus(true);
      setSnackBarSeverity("error");
      setSnackBarMessage("Failed to upload file");
    }
  };
  const handleData = async () => {
    try {
      let response = await axios.get(`${baseUrl}/assetUpload`);
      if (response.data.data) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // useEffect(() => {
  //   console.log("data :", uploadData);
  // }, [uploadData]);

  useEffect(() => {
    handleData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "uploadId",
        header: "ID",
        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "uploadingAssetType",
        header: "Asset Type",

        size: 150,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: "updatedAt", //normal accessorKey
        header: "Last Updated At",
        size: 200,
        Header: ({ column }) => (
          <span style={{ whiteSpace: "normal" }}>
            {column.columnDef.header}
          </span>
        ),
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return (
            <span
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#757575",
                whiteSpace: "normal",
              }}
            >
              {/* {currentUser ? user.name : cell.getValue()} */}

              {value ? value : "N/A"}
              {/* {value ? value.split(" ")[0] : "N/A"} */}
            </span>
          );
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        sortable: false,
        filterable: false,
        enableColumnActions: false,

        size: 120,
        Cell: ({ cell }) => {
          return (
            <Button onClick={() => showUpload(cell)}>
              <UploadFileIcon />
            </Button>
          );
        },
      },
    ],
    [currentUser]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    //   state: { showSkeletons: loading },
    defaultColumn: { minSize: 20, maxSize: 400, size: 80 },
    muiTableHeadCellProps: {
      sx: {
        padding: "10px",
        background: "#F1F5FE",
        // whiteSpace:"normal"
      },
    },
    muiTableProps: {
      sx: {
        tableLayout: "fixed",
        // width: '100%'
      },
    },
    enableDensityToggle: false,
    enableStickyHeader: true,
  });

  return (
    <>
      {/* <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          startIcon={<UploadFileIcon />}
          sx={{
            margin: "1rem",

            border: "1px solid #F1F5FE",
            backgroundColor: "#F1F5FE",
            color: "#0288d1",
            fontSize: "12px",
            textTransform: "capitalize",
            height: "max-content",
            "&:hover": {
              backgroundColor: "#F1F5FE",
              color: "#0288d1",
              border: "1px solid #0288d1",
              boxShadow: "1px #000000",
            },
          }}
          onClick={showUpload}
        >
          Upload
        </Button>
      </div> */}
      <MaterialReactTable table={table} />
      {/* *modal ADD NEW DATA***  */}
      <Dialog open={showupload.show} sx={{ padding: "20px" }}>
        <DialogContent sx={{ padding: "25px" }} className="wbScroll">
          <Stack
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={8} rowSpacing={3}>
              <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>
                  Upload File<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  type={"file"}
                  InputProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="file"
                  //   value={uploadData}
                  onChange={handleOnChange}
                  autoComplete="off"
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>
                  Asset Type
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  required
                  InputProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="uploadingAssetType"
                  autoComplete="off"
                  value={data.uploadingAssetType}
                  onChange={handleOnChange}
                />
              </Grid> */}
            </Grid>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleUpload(showupload.cell)}
            variant="contained"
            sx={{
              backgroundColor: "#0288d1",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#140B96",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Upload
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              backgroundColor: "#E0E0E0",
              textTransform: "none",
              color: "black",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        autoHideDuration={4000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {loading && (
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

export default AdminCor;
